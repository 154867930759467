module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-35086661-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stephen Richard - Portfolio","short_name":"stephen-richard","start_url":"/","background_color":"#FFFFFF","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84eb5eb119ca1d8018ee4eca7aa16529"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
